<template>
	<div class="container">
		<div class="page-sub-box clearfix ui-glid-box per33 ui-33">
			<table class="table_form line-bin">
				<thead class="sub_title_txt">
					<tr>
						<td colspan="3">
							<h2>내선 형식 {{ patternData.id ? '수정' : '등록' }}</h2>
						</td>
					</tr>
				</thead>
				<colgroup>
					<col style="width:250px;">
					<col style="width:auto;">
				</colgroup>

				<tbody>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>사이트</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="patternData.siteId"
								placeholder="사이트 선택"
								:items="infoPushSiteList"
								display-expr="siteNm"
								value-expr="siteId"
								:styling-mode="stylingMode"
								:width="300"
								:height="30"
								:disabled="patternData.id ? true : false"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>패턴</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxTextBox
								v-model="patternData.pattern"
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="300"
								placeholder="패턴을 입력해주세요."
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>설명</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxTextBox
								v-model="patternData.description"
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="300"
								placeholder="설명을 입력해주세요."
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>수신시 설정</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="patternData.inContentId"
								:items="contentList"
								display-expr="description"
								value-expr="id"
								:styling-mode="stylingMode"
								:width="300"
								:height="30"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>발신시 설정</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="patternData.outContentId"
								:items="contentList"
								display-expr="description"
								value-expr="id"
								:styling-mode="stylingMode"
								:width="300"
								:height="30"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>순서</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxNumberBox
								v-model="patternData.patternOrd"
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="300"
								placeholder="순서"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">사용여부</label>
						</th>
						<td class="clearfix">
							<DxSwitch v-model="viewCd"/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<div class="bottom-btn-wrap">
					<DxButton text="저 장" :width="120" :height="40" class="default filled txt_S medium" :onClick="() => onSave()" />
					<DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" :onClick="() => $_goPrePage()" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxSwitch } from 'devextreme-vue/switch';
import { isSuccess } from '../../../../../plugins/common-lib';


let vm = this;

export default {
	components: {
		DxTextBox,
		DxNumberBox,
		DxSelectBox,
		DxButton,
		DxSwitch,
	},
	props: {},
	watch: {
		infoPushSiteList: function(newVal) {
			if(newVal.length) {
				this.patternData.siteId = newVal[0].siteId;
			}
		},
	},
	data() {
		return {
			infoPushSiteList: [],
			contentList: [],
			viewCd: false,
			stylingMode: 'outlined',
			config: {
				pageSetting: {
					//pageData pageSetting 관련
					config: {},
				},
			},
			patternData: {
				inContentId: null,
				outContentId: null
			},
		};
	},
	computed: {
	},
	methods: {
		async onSave() {
			let validation = true;

			if(!this.patternData.siteId) {
				this.$_Msg("사이트를 선택해주세요.");
				validation = false;
			} else if(!this.patternData.pattern) {
				this.$_Msg("패턴을 입력해주세요.");
				validation = false;
			} else if(!this.patternData.description) {
				this.$_Msg("설명을 입력해주세요.");
				validation = false;
			} else if(!this.patternData.inContentId && !this.patternData.outContentId) {
				this.$_Msg("설정 유형 선택이 잘못되었습니다.");
				validation = false;
			} else if(!this.patternData.patternOrd) {
				this.$_Msg("순서를 입력해주세요.");
				validation = false;
			}

			const checkPattern = this.checkRegExp(this.patternData.pattern);
			if(!checkPattern) {
				this.$_Msg("패턴 형식을 확인해주세요.");
				validation = false;
			}

			if(!validation) {
				return;
			}

			let payload = {
				actionname: 'INFOPUSH_PATTERN_LIST_ALL',
				data: {
					params: {
						pattern: this.patternData.pattern
					}
				},
				loading: false,
			};

			this.CALL_API(payload).then(async (res) => {
				if(res.status === 200 && res.data.header.resCode === 'success' && res.data.data.length) {
					this.$_Msg("중복된 패턴입니다.");
					validation = false;
				} else {
					this.patternData.viewCd = this.viewCd ? this.$_getUsedCode.id : this.$_getUnUsedCode.id;
					payload = {
						actionname: 'INFOPUSH_PATTERN_UPDATE',
						data: {
							data: [this.patternData]
						},
						loading: false,
					};

					const res = await this.CALL_API(payload);
					if (isSuccess(res)) {
						await this.$_Msg("저장되었습니다.");
						this.$_goPrePage();
					}
				}
			})
		},
		async selectContentList() {
			const payload = {
				actionname: 'INFOPUSH_CONTENT_LIST_ALL',
				data: {
					params: {
						viewCd: this.$_getUsedCode.id
					}
				},
				loading: false,
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {
				this.contentList = [{ id: null, description: '미적용' }, ...res.data.data];
			}
		},
		/**@description : 패턴이 정규식 형식에 맞는지 검사 */
		checkRegExp(str) {
			try {
				new RegExp(str);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getInfoPushSiteList() {
			const payload = {
				actionname: 'INFOPUSH_SITE_LIST_ALL',
				data: {
					params: {
						viewCd: this.$_getUsedCode.id,
						sort: '+siteOrd'
					}
				}
			}
			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				this.infoPushSiteList = res.data.data
			}
		},
		/**@description : 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.getInfoPushSiteList();
			this.selectContentList();
		},
		createdData() {
			this.patternData = this.$store.getters.getDetailParams?.patternData || { inContentId: null, outContentId: null };
			if(this.patternData?.viewCd) {
				this.viewCd = this.patternData?.viewCd === this.$_getUsedCode.id;
			}
			vm = this;
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {},
};
</script>

<style scoped>
.device-img > img {
	align-self: center;
}

tbody {
    display: table-row-group;
    vertical-align: top;
}

#dropzone-external > * {
    pointer-events: none;
}

.widget-container > span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
}

</style>
